var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"category__bg",style:({ backgroundColor: _vm.isSupplierCategoryItem ? _vm.supplierData.product_card_background_color : _vm.supplierData.chosen_card_background_color })},[(!_vm.productItem.add_vat)?_c('p',{staticClass:"no-vat-remark"},[_vm._v(" "+_vm._s(_vm.$t('No VAT'))+" ")]):_vm._e(),_c('CategoryShare'),_c('div',{staticClass:"category__star",on:{"click":function($event){return _vm.changeFavorite(_vm.productItem.id)}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"26","height":"25","viewBox":"0 0 26 25"}},[_c('path',{style:({ fill: _vm.productItem.favorite ? _vm.supplierData.favorite_star_color : '#fff'}),attrs:{"d":"M32.158.857,28.97,7.321l-7.133,1.04a1.563,1.563,0,0,0-.864,2.666l5.161,5.029-1.221,7.1A1.561,1.561,0,0,0,27.178,24.8l6.381-3.354L39.941,24.8a1.563,1.563,0,0,0,2.265-1.645l-1.221-7.1,5.16-5.029a1.563,1.563,0,0,0-.864-2.666l-7.133-1.04L34.961.857a1.564,1.564,0,0,0-2.8,0Z","transform":"translate(-20.5 0.013)"}})])]),(_vm.productItem.show_additional_details)?_c('img',{staticClass:"infor-icon",attrs:{"src":"/assets/img/icons/info-white.svg","alt":""},on:{"click":function($event){return _vm.openInforPopup()}}}):_vm._e(),_c('div',{staticClass:"communication-category__unit-caption"},[_c('div',{staticClass:"communication-category__product-name",style:({ color: _vm.isSupplierCategoryItem ? _vm.supplierData.product_font_color : _vm.supplierData.chosen_product_font_color })},[_vm._v(" "+_vm._s(_vm.productItem.name)+" ")])]),_c('div',{staticClass:"communication-category__logo-wrapper",style:({
        'background-color': _vm.supplierData.product_side_logo_background_color,
      })},[_c('img',{staticClass:"img",attrs:{"src":_vm.supplierData.image,"alt":""}})]),_c('div',{staticClass:"valid_day-price"},[_c('div',{staticClass:"valid-day"},[_vm._v(" "+_vm._s(_vm.$t('Valid on days'))+": "+_vm._s(_vm.getValidDay(_vm.productItem.id))+" ")]),_c('div',[_c('span',{staticClass:"category__price"},[_vm._v(_vm._s(_vm.$t('Price'))+": ")]),_c('b',{staticClass:"category__price-value"},[_vm._v(" "+_vm._s(_vm.productItem.price)+" ₪ ")])])])],1),_c('div',{staticClass:"category__info"},[_c('p',{staticClass:"text category__text",domProps:{"innerHTML":_vm._s(_vm.productItem.description)}}),_c('div',{staticClass:"category-detail_block mb-5"},[(_vm.productItem.call_minute)?_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('Minutes of conversation'))+":")]),_vm._v(" "+_vm._s(_vm.productItem.call_minute)+" ")]):_vm._e(),(_vm.productItem.sms)?_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('Messages'))+":")]),_vm._v(" "+_vm._s(_vm.productItem.sms)+" ")]):_vm._e(),(_vm.productItem.abroed_price)?_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('Price use abroad Israel'))+":")]),_vm._v(" "+_vm._s(_vm.productItem.abroed_price)+" ")]):_vm._e(),(_vm.productItem.other1)?_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('Other 1'))+":")]),_vm._v(" "+_vm._s(_vm.productItem.other1)+" ")]):_vm._e(),(_vm.productItem.browsing_package)?_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('Surfing'))+":")]),_vm._v(" "+_vm._s(_vm.productItem.browsing_package)+" ")]):_vm._e(),(_vm.productItem.other2)?_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('Other 2'))+":")]),_vm._v(" "+_vm._s(_vm.productItem.other2)+" ")]):_vm._e()]),_c('router-link',{staticClass:"link category__button pointer",attrs:{"to":{
        name: 'CommunicationProduct',
        params: {
          id: _vm.productItem.id,
          supplier_id: _vm.productItem.supplier_id,
        },
      },"tag":"a","exact":""}},[_vm._v(_vm._s(_vm.$t('Time')))])],1),_c('InforPopup',{attrs:{"inforData":_vm.inforData,"modal_key":_vm.modal_key}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }