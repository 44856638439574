<template>
  <main class="category page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">
      {{ $t("Cancellation of freepaid recharge") }}
    </p>

    <section class="category__switch">
      <a
        v-for="(supplierProductCategory, index) in supplierProductCategories"
        :key="index"
        class="link category__link pointer"
        :class="{
          category__link_active:
            supplierProductCategory.value == currentSupplierProductCategory,
        }"
        @click="selectSupplierProductCategory(supplierProductCategory.value)"
        >{{ supplierProductCategory.label }}</a
      >
    </section>
    <!-- <section v-show="currentSupplierProductCategoryItems.length">
      <div class="category__container">
        <CategoryUnit
          v-for="(product_item, index) in currentSupplierProductCategoryItems"
          :key="index"
          class="category__unit"
          :productItem="product_item"
          :currentSupplierProductCategory="currentSupplierProductCategory"
          :supplierData="supplierData"
          :productData="productData"
          :supplierId="supplierId"
          :isFavourite="true"
        ></CategoryUnit>
      </div>
    </section> -->

    <section v-if="special_products.virtual" class="category__more">
      <h2 class="title category__title-h2">
        {{ $t('Additional products') }}
      </h2>
      <div class="category__wrapper">
        <template
          v-for="(product_item, index1) in special_products.virtual"
        >
          <CategoryUnit
            v-if="!product_item.favorite"
            :key="index1"
            class="category__unit"
            :productItem="product_item"
            :currentSupplierProductCategory="currentSupplierProductCategory"
            :supplierData="supplierData"
            :productData="productData"
            :supplierId="supplierId"
            :isSupplierCategoryItem="false"
          ></CategoryUnit>
         </template>
      </div>
    </section>
    <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import CategoryUnit from '../../../components/communication/CategoryUnit'

export default {
  name: 'CancelSupplier',
  components: {
    CategoryUnit 
  },
  data() {
    return {
      supplierProductCategories: [
        {
          label: this.$t('Favorites'),
          value: 'favorite',
        },
        {
          label: this.$t('Recommended'),
          value: 'recommended',
        },
        {
          label: this.$t('Internet'),
          value: 'internet',
        },
      ],
      currentSupplierProductCategory: 'favorite',
    };
  },
  computed: {
    ...mapState({
      loading: state => state.supplier.loading,
      supplier_items: (state) => state.supplier.supplier_items,
      suppliers: (state) => state.supplier.suppliers,
      special_products: state => state.supplier.special_products,
      suppliers_products: (state) => state.supplier.suppliers_products,
    }),
    supplierId() {
      return this.$route.params.id;
    },
    supplierData() {
      let supplierDataFromSuppliersItemsData = null;
      if (this.supplier_items) {
        for (let supplier_type in this.supplier_items) {
          for (let suppliers_type_item of this.supplier_items[supplier_type]) {
            if (suppliers_type_item.id == this.supplierId) {
              supplierDataFromSuppliersItemsData = { ...suppliers_type_item };
              break;
            }
          }
        }
      }

      let supplierDataFromSuppliersData = null;
      if (this.suppliers && this.supplierId in this.suppliers) {
        supplierDataFromSuppliersData = { ...this.suppliers[this.supplierId] };
      }

      let supplierData = supplierDataFromSuppliersData
        ? supplierDataFromSuppliersData
        : supplierDataFromSuppliersItemsData;
      return supplierData ? supplierData : null;
    },
    productData() {
      let productData = [];

      if (this.suppliers_products) {
        for (let supplier_id in this.suppliers_products) {
          for (let supplier_product_type in this.suppliers_products[
            supplier_id
          ]) {
            for (let supplier_product of this.suppliers_products[supplier_id][
              supplier_product_type
            ]) {
              if (
                supplier_product.hasOwnProperty('id')
              ) {
                productData.push(supplier_product);
              }
            }
          }
        }
      }
      return productData;
    },
  },
  async created() {
    this.getSupplier({ supplier_id: this.supplierId });
    this.getSupplierProducts({ supplier_id: this.supplierId });
    this.getProductsById({ supplier_id: this.supplierId });
  },
  methods: {
    ...mapActions('supplier', {
      getSupplier: 'getSupplier',
      getSupplierProducts: 'getSupplierProducts',
      getProductsById: 'getProductsById',
    }),
    selectSupplierProductCategory(supplierProductCategory) {
      // if (supplierProductCategory == this.currentSupplierProductCategory) {
      //   return;
      // }
      // this.currentSupplierProductCategory = supplierProductCategory;
    },
  }
};
</script>

<style lang="scss">
.communication-header_logo {
  display: none;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  img {
    width: 60px;
  }
}

.category__switch {
  margin-top: 15px;
}
.category__more {
  margin-top: 15px;
}

@media screen and (max-width: 767px) {
  .category__wrapper {
    justify-content: flex-start;
  }
  .category {
    padding: 10px 0;
  }
  .category__top-wrapper {
    position: relative;
    .category__title {
      position: absolute;
      right: 20px;
      top: 0;
      font-size: 18px;
    }
    .communication-header_logo {
      display: flex;
    }
  }
  .category__switch {
    margin-top: 10px;
  }
  .category__more {
    margin-top: 10px;
  }
}
</style>

<style lang="scss" scoped>
.category {
  width: auto;
}
.category__link {
  font-size: 18px;
}
.ltr-type {
  @media (min-width: 1365px) {
    .category__unit:not(:nth-child(4n)) {
      margin-right: 15px;
      margin-left: 0;
    }
  }
}
</style>